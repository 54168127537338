@font-face {
    font-family: 'Fira Sans Bold';
    src: url("/assets/src/fonts/FiraSans-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Fira Sans Semibold';
    src: url("/assets/src/fonts/FiraSans-SemiBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Fira Sans Medium';
    src: url("/assets/src/fonts/FiraSans-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Fira Sans Medium-italic';
    src: url("/assets/src/fonts/FiraSans-MediumItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Fira Sans Light';
    src: url("/assets/src/fonts/FiraSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }

body {
    font-family: 'Fira Sans Medium';
    font-size: 20px;
    line-height: 30px;
}

h1 {
    font-family: 'Fira Sans Semibold';
    position: relative;
    font-size: 4.000em;
    text-align: center;
    padding: 150px 130px 20px 130px;
    line-height: 100px;
}

h2 { 
    font-size: 1.200em;
    color: $white;
    display: table;
    font-weight: normal;
}

h3 {
    font-size: 3.000em;
    padding-top: 50px;
    padding-bottom: 30px;
    line-height: 80px;
}

h4 {
    font-size: 2.000em;
    padding-top: 50px;
    padding-bottom: 30px;
}

@media (max-width: 1050px) {

}

@media (max-width: 868px) {
    body {
        font-size: 18px;
    }
}

@media (max-width: 650px) {
    body {
        font-size: 16px;
    }
}

@media (max-width: 400px) {
    h1 {
        font-size: 3.5em;
    }
}