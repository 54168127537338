html {
    scroll-behavior: smooth;
  }

#main {
    width: calc(100% - 250px);
}

#right {
    width: 250px;
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    margin-right: 0;
    border-left: 2px solid $blue;
} 

.block {
    overflow: visible;
}

.hamburger {
    display: none;
    position: fixed;
    top: 132px;
    right: 50px;
    z-index: 3;
    p {
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        cursor: pointer;
        background: #cdcdcd;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
        &:nth-last-child(2) {
            transform-origin: 0% 100%;
        }
        &:first-child {
            transform-origin: 0% 0%;
        }
    }
    &.toggle p {
        opacity: 1;
        transform: rotate(45deg) translate(-5px, -19px);
        background: $grey;
    }

    &.toggle p:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }
    &.toggle p:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, 15px);
    }
}

body.navIsOpen {
    #main {
        right: 280px;
    }
    #right {
        margin-right: 0;
    }
    #container, .inside {
        overflow: visible;
    }
}

.back {
    &:after {
        content: url(/assets/src/img/Pfeil_unten.svg);
        width: 50px;
        height: 25px;
        position: absolute;
        left: 50px;
        margin-top: 180px;
        transform: rotate(90deg);
    }
}

.full_width {
    #main {
        width: 100%;
    }
}

@media (max-width: 1150px) {
    .back {
        &:after {
            left: 20px;
            margin-top: 30px;
            z-index: 999;
        }
    }
}

@media all and (max-width: 1050px) {
    #main {
        width: calc(100% - 0px);
        right: 0;
        transition: 0.5s
    }
    #right {
        margin-right: -252px;
        transition: 0.5s;
        //overflow-y: scroll;
    }
    #container, .inside {
        overflow: hidden;
        transition: 0.5s
    }
    .hamburger {
        display: block;
    }
}

@media (max-width: 650px) {
    .hamburger {
        top: 45px;
    }
}

@media (max-width: 400px) {
    .hamburger {
        right: 10px;
    }
}