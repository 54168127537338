.mod_onepage_navigation {
    padding-top: 50px;
    ul {
        list-style: none;
        li {
            padding: 20px 0;
            &.level_2 {
                padding: 10px 0;
            }
            a{
                margin-left: 20px;
                text-decoration: none;
                color: $black;
                &:before {
                    background-image: url(/assets/src/img/Ellipse.jpg);
                    left: -29px;
                    content: "";
                    background-size: 16px 16px;
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    background-repeat: no-repeat;
                    position: relative;
                    top: 2px;
                }
                &:hover, &.active {
                    &:before {
                        background-image: url(/assets/src/img/Ellipse_voll.jpg);
                        left: -29px;
                        content: "";
                        background-size: 16px 16px;
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        background-repeat: no-repeat;
                        position: relative;
                        top: 2px;
                    }
                }
            }
            a.level_2 {
                font-style: italic;
                &:before {
                    background-image: url(/assets/src/img/Ellipse.jpg);
                    left: -28px;
                    content: "";
                    background-size: 14px 14px;
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    background-repeat: no-repeat;
                    position: relative;
                    top: 0;
                }
                &:hover, &.active {
                    &:before {
                        background-image: url(/assets/src/img/Ellipse_voll.jpg);
                        left: -28px;
                        content: "";
                        background-size: 14px 14px;
                        display: inline-block;
                        width: 14px;
                        height: 14px; 
                        background-repeat: no-repeat;
                        position: relative;
                        top: 0;
                    }
                }
            }
        }
    }
}  

.mod_customnav {
    position: relative;
    ul {
        list-style: none;
        margin-left: 40px;
        padding-top: 50px;
        li a {
            text-decoration: none;
            font-size: 15px;
            color: $black;
        }
    }
}

@media all and (max-width: 1050px) {
    .mod_customnav {
        ul {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
    .mod_onepage_navigation {
        ul li {
            padding: 3% 0;
            &.level_2 {
                padding: 2% 0;
            }
        }
    }
}

@media all and (max-width: 400px) {
    .mod_customnav ul {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}