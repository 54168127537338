.mod_article {
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 100px;
    box-sizing: border-box;
    position: relative;
    &.impress {
        padding-bottom: 50px;
    }
    .ce_text {
        overflow: hidden;
    }
    .ce_text p a {
        text-decoration: none;
        color: white;
        border-bottom: 1px solid white;
        &:after {
            content: url('/assets/src/img/externerlink_weiss.svg');
            position: absolute;
            width: 20px;
            height: 20px;
            fill: $white;
        }
        &.fliess {
            color: $blue;
            &:after {
                fill: $blue;
            }
        }
    }
    &.first {
        //height: 100vh;
        &:after {
            /*
            content: url('/assets/src/img/landingpage_image.png');
            left: -100px;
            position: absolute;
            margin-top: 80px
            */
        }
        .ce_text {
            p {
                padding: 20px 200px;
                text-align: center;
            }
        }
    }
}

.btn_down {
    &:after {
        content: url('/assets/src/img/Pfeil_unten.svg');
        width: 50px;
        height: 25px;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        margin-top: 50px;
    }
}

.img_left {
    max-width: 80%;
    height: auto;
    position: relative;
    left: -55%;
    margin-top: 150px;
}

h2.ce_headline {
    margin: 0 auto;
    padding: 5px 30px;
    background: $grey;
    &:not(.first) {
        position: relative;
        left: 4%;
        color: $blue;
    }
    &.first {
        position: relative;
        right: 4%;
        background: $blue;
    }
}

.a_5 {
    display: flex;
    flex-wrap: wrap;
    .headline_wrapper {
        flex-basis: 100%;
    }
    &.blue {
        background-color: $blue;
        color: $white;
        padding-bottom: 150px;
        box-sizing: border-box;
        &:after {
            content: '';
            background-color: $blue;
            position: absolute;
            top: 0;
            width: 3500px;
            height: 100%;
            left: -3500px;

        }
    }
    h3.ce_headline {
        flex-basis: 100%;
        padding: 50px 0 30px 46px;
    }
    .ce_text {
        flex-basis: 50%;
        max-width: 50%;
        padding: 0 50px;
        box-sizing: border-box;
        p {
            padding-bottom: 20px;
        }
        table {
            td {
                padding-bottom: 20px;
                span {
                    text-decoration: none !important;
                    border-bottom: 1px solid black;
                }
            }
        }
        ul {
            
            list-style: none;
            li {
                
                padding-bottom: 10px;
                display: flex;
                column-gap: 10px;
                &:before {
                    background-image: url(/assets/src/img/Ellipse_voll.jpg);
                    content: " ";
                    background-size: 10px 10px;
                    background-position: left center;
                    display: block;
                    width: 100%;
                    max-width: 10px;
                    height: 30px;
                    background-repeat: no-repeat; 

                    
                }
            }
        }
    }
}

.a_4 {
    display: flex;
    flex-wrap: wrap;
    .headline_wrapper {
        flex-basis: 100%;
    }
    h4.ce_headline {
        flex-basis: 100%;
        padding: 50px 0 30px 46px;
    }
    .ce_text {
        flex-basis: 40%;
        max-width: 40%;
        padding: 0 50px;
        box-sizing: border-box;
        p {
            padding-bottom: 20px;
        }
        table {
            td {
                padding-bottom: 20px;
            }
        }
        ul {
            list-style: none;
            li {
                
                padding-bottom: 10px;
                display: flex;
                column-gap: 10px;
                &:before {
                    background-image: url(/assets/src/img/Ellipse_voll.jpg);
                    content: " ";
                    background-size: 10px 10px;
                    background-position: left center;
                    display: block;
                    max-width: 10px;
                    width: 100%;
                    height: 30px;
                    background-repeat: no-repeat; 

                    
                }
            }
        }
    }
    .ce_image {
        flex-basis: 60%; 
        max-width: 60%;
        position: relative;
        &:after {
            content: url(/assets/src/img/blue_corner.svg);
            width: 200px;
            height: 100px;
            position: absolute;
            top: -30px;
            right: -30px;
            z-index: -1;
        }
    }
}

.a_8 {
    background: $blue;
    color: $white;
    display: flex;
    flex-wrap: wrap;
    max-width: 68.6vw;
    padding-bottom: 150px;
    margin: 0;
    .headline_wrapper {
        flex-basis: 100%;
    }
    h3.ce_headline {
        flex-basis: 100%;
        padding: 50px 0px 30px 46px;
        max-width: 1000px;
        margin: 0px 20.5vw;
        box-sizing: border-box;
    }
    .ce_text {
        flex-basis: 80%;
        padding: 0 46px;
        box-sizing: border-box;
        max-width: 1000px;
        margin: 0 20.5vw;
    }
}

.a_10 {
    p {
        padding: 0 30px !important;
        text-align: left !important;
    }
    h3 {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 1338px) {
    .a_8 {
        h3.ce_headline {
            margin: 0 17.1vw;
        }
        .ce_text {
            margin: 0 17.1vw;
        }
    }
}

@media (max-width: 1337px) and (min-width: 1051px) {
    .mod_article {
        margin-right: 40px;
    }
}

@media (max-width: 1050px) {
    .mod_article {
        margin-top: 20px;
    }
    .mod_article:not(.first) {
        padding: 100px 30px 0 0;
        &.a_8 {
            max-width: 100vw;
            padding-bottom: 100px !important;
            h3.ce_headline {
                margin: 0 auto;
            }
            .ce_text {
                flex-basis: 100%;
                max-width: 100%;
                margin: 0 auto;
            }
        }
        h1 {
            padding: 150px 100px 20px 100px;
        }
    }
    .img_left {
        left: -20%;
        margin-top: 0;
    }
}

@media (max-width: 868px) {
    .mod_article:not(.first) {
        padding: 50px 45px 0 0;
        &.a_5 {
            .ce_text {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }
    .img_left {
        left: -12%;
        width: 380px;
        margin-top: 20%;
    }
    .mod_article.first {
        .ce_text {
            h1 {
                padding: 100px 40px 20px 40px;
            }
            p {
                padding: 20px 130px;
            }
        }
    }
}

@media (max-width: 650px) {
    h2.ce_headline {
        margin-left: 40px;
        &:not(.first) {
            left: 8%;
        }
        &.first {
            right: 8%;
        }
    }
    .mod_article.first {
        //padding: 100px 10px;
        .ce_text {
            h1 {
                padding: 30px 0px 20px 0px;
                line-height: 70px;
            }
            p {
                padding: 20px 0px;
            }
        }
    }
    .img_left {
        left: -22%;
        width: 330px;
        margin-top: 14%;
    }
    .mod_article:not(.first) {
        padding: 50px 0 0 0;
        &.a_4 {
            .ce_text {
                flex-basis: 100%;
                max-width: 100%;
            }
            .ce_image {
                flex-basis: 100%;
                max-width: 100%;
                margin-top: 30px;
                margin-right: 45px;
            }
        }
    }
}

@media (max-width: 400px) {
    h2.ce_headline {
        margin-left: 20px;
    }
    .img_left {
        margin-top: 30%;
    }
    .mod_article:not(.first) {
        &.a_5 {
            .ce_text {
                padding: 0 25px;
                overflow: hidden;
            }
            h3.ce_headline {
                padding: 50px 0 30px 25px;
            }
        }
        &.a_4 {
            .ce_text {
                padding: 0 25px;
                overflow: hidden;
            }
            h4.ce_headline {
                padding: 50px 0 30px 25px;
            }
        }
        &.a_8 {
            h3.ce_headline {
                padding: 50px 0px 30px 25px;
            }
            .ce_text {
                padding: 0 25px;
            }
        }
    }
}